import React, { useState, useEffect } from 'react';

const AvisoMudancaDominio = () => {
  const [countdown, setCountdown] = useState(15);

  useEffect(() => {
    if (countdown === 0) {
      window.location.href = 'https://147.tools';
      return;
    }
    const timer = setInterval(() => {
      setCountdown(prev => prev - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [countdown]);

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>147 tracker</h1>
      <p style={styles.paragraph}>
        Prezado(a) usuário(a),
      </p>
      <p style={styles.paragraph}>
        Informamos que o domínio <strong>147.wtf</strong> será alterado para <strong>147.tools</strong> em breve. Estamos emitindo este aviso antecipado para que você possa atualizar seus favoritos e evitar qualquer interrupção em seu acesso aos nossos serviços.
      </p>
      <p style={styles.paragraph}>
        Pedimos que comecem a utilizar o novo domínio para evitar possíveis desinformações no futuro.
      </p>
      <p style={styles.paragraph}>
        <strong>Novo domínio:</strong> <a href="https://147.tools" style={styles.link}>147.tools</a>
      </p>
      <p style={styles.signature}>
        Atenciosamente,<br />Equipe 147.
      </p>
      <p style={styles.redirectMessage}>
        Você será redirecionado automaticamente em {countdown} segundo{countdown !== 1 ? 's' : ''}.
      </p>
    </div>
  );
};


const styles = {
  container: {
    maxWidth: '600px',
    width: '100%',
    margin: '0 auto',
    padding: '40px 20px',
    fontFamily: "'Roboto', sans-serif",
    lineHeight: '1.6',
    textAlign: 'left',
    backgroundColor: 'transparent',
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
    color: '#fff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxSizing: 'border-box', 
  },
  title: {
    fontSize: '2.5em',
    marginBottom: '20px',
    color: '#fff',
    textAlign: 'center', 
  },
  paragraph: {
    fontSize: '1.2em',
    marginBottom: '20px',
  },
  link: {
    color: '#492cdb',
    textDecoration: 'none',
  },
  signature: {
    marginTop: '40px',
    fontStyle: 'italic',
    fontSize: '1.2em',
  },
  redirectMessage: {
    marginTop: '20px',
    fontSize: '1.2em',
  },
};

styles.container['@media (max-width: 600px)'] = {
  padding: '20px 15px',
};

export default AvisoMudancaDominio;
